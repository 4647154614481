import { BASE_URL } from "./constants.js";

class Api {
  constructor({ baseUrl }) {
    this._baseUrl = baseUrl;
    this._headers = {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("jwt")}`,
    };
  }

  _fetchWithCheck(endpoint, config = {}) {
    return fetch(`${this._baseUrl}/${endpoint}`, {
      ...config,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("jwt")}`,
        ...config.headers,
      },
    }).then((response) => {
      return response.json().then((data) => {
        if (!response.ok) {
          throw new Error(data.message);
        }
        return data;
      });
    });
  }

  getButtons() {
    return this._fetchWithCheck("buttons");
  }
}

const mainApi = new Api({
  baseUrl: BASE_URL,
});

export default mainApi;
