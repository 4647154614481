import React, { useContext, useState } from "react";
import headerLogo from "../../images/header-logo.png";
import "./Header.css";

const Header = ({handleSignOut}) => {

  return (
    <>
    <div className="header">
      <div>
      <img
        src={headerLogo}
        className="header__logo"
        alt="ae2 logo"
      />
        {/* <div>Welcome</div>
        <div className="header__user">{capitalizeFirstLetter(currentUser.login)}</div> */}
      </div>
    </div>
    </>
  );
};

export default Header;
