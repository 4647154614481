import "./ButtonCard.css";
import React, { useEffect, useState } from "react";
import ButtonPopup from "../ButtonPopup/ButtonPopup.jsx";

const ButtonCard = ({ button }) => {
  const { icon, help } = button;
  const { eng, ru } = help || {};
  let { name } = button;

  // Замена "Data Template Group" на "Import Ai - Ps Template"
  if (name === "Data Template Group") {
    name = "Import Ai - Ps Template";
  }else if(name === "Controls Group"){} else {
    
    name = name.replace(' Group', '');
  }

  const urlSegments = window.location.pathname.split("/");
  const lastSegment = urlSegments[urlSegments.length - 1];

  const [isPopupOpen, setPopupOpen] = useState(false);
  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  
    if (!isPopupOpen) {
      const newUrl = `${window.location.pathname}?guide=${name}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    } else {
      window.history.pushState({}, '', window.location.pathname);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const buttonName = urlParams.get('guide');
    if (buttonName && buttonName === name) {
      setPopupOpen(true);
    }
  }, [name]);
  
  // Проверка, содержит ли ru строку "https://"
  const isButtonDisabled = !ru.includes("https://");

  return (
    <div className={`button-card-wrapper ${isButtonDisabled ? 'button-card--disabled' : ''}`}>
      <li className={`button-card ${isButtonDisabled ? 'button-card--disabled' : ''}`} onClick={!isButtonDisabled ? togglePopup : null}>
        <div className="button-card__main">
          <div>
            <div dangerouslySetInnerHTML={{ __html: icon }} />
          </div>
          <h4 className="button-card__title">{name}</h4>
        </div>
      </li>
      {isPopupOpen && (
        <ButtonPopup
          button={button}
          onClose={togglePopup}
        />
      )}
    </div>
  );
};

export default ButtonCard;
