import "./App.css";
import mainApi from "../../utils/api.js";
import ButtonList from "../ButtonList/ButtonList.jsx";
import Preloader from "../Preloader/Preloader.jsx";
import { useState, useEffect } from "react";

export default function App() {
    const [buttons, setButtons] = useState([]);

    const [isLoader, setIsLoader] = useState(false);

    useEffect(() => {
        const fetchMainButtons = async () => {
            try {
                setIsLoader(true);
                const fetchedButtons = await mainApi.getButtons("buttons");
                setButtons(fetchedButtons);
            } catch (err) {
                // Handle error
            } finally {
                setIsLoader(false);
            }
        };

        fetchMainButtons();
    }, []);

    // useEffect(() => {
    //     console.log(buttons); // Logs the updated state
    // }, [buttons]); // Runs when `buttons` state changes

    return (
        <>
        <div>
            <Preloader isOpen={isLoader} />
            {!isLoader && <ButtonList buttons={buttons} />}
        </div>
        </>
    );
}
