import "./ButtonPopup.css";
import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import Preloader from "../Preloader/Preloader.jsx"; // Импортируем Preloader

const ButtonPopup = ({ button, onClose }) => {
  const { category, help } = button || {};
  const { eng, ru } = help || {};
  let { name } = button;

  const [iframeSrc, setIframeSrc] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Добавляем состояние для отслеживания загрузки iframe
  const [iframeSize, setIframeSize] = useState({ width: "1280", height: "720" }); // Начальные размеры iframe
  const [copySuccess, setCopySuccess] = useState(''); // Состояние для отслеживания статуса копирования

  const browserLang = navigator.language || navigator.userLanguage;
  const cisLanguages = ["ru", "uk", "kk", "be"];

  if (name === "Data Template Group") {
    name = "Import Ai - Ps Template";
  } else {
    // Удаление слова "Group" из названия
    name = name.replace(' Group', '');
  }

  // Функция для копирования URL в буфер обмена
// Функция для копирования URL в буфер обмена
const copyToClipboard = () => {
  const currentUrl = window.location.href; // Получаем текущий URL

  navigator.clipboard.writeText(currentUrl).then(() => {
    setCopySuccess('Ссылка скопирована!'); // Уведомление об успешном копировании
  }, () => {
    setCopySuccess('Ошибка при копировании'); // Уведомление об ошибке
  });
};

  // Функция обновления размера iframe
  const updateIframeSize = () => {
    setIframeSize({
      width: window.innerWidth / 2,
      height: window.innerHeight / 2
    });
  };

  useEffect(() => {

    setIsLoading(true); // Активируем загрузку

    if (cisLanguages.some(lang => browserLang.startsWith(lang))) {
      setIframeSrc(convertYouTubeUrl(ru));
    } else {
      setIframeSrc(convertYouTubeUrl(eng));
    }

    // Устанавливаем размеры iframe при монтировании компонента
    updateIframeSize();
    // Добавляем обработчик события resize для обновления размеров
    window.addEventListener("resize", updateIframeSize);

    // Очистка обработчика события
    return () => window.removeEventListener("resize", updateIframeSize);
  }, [category, ru, eng]);

  function convertYouTubeUrl(url) {
    const videoId = url.split('/').pop();
    return `https://www.youtube.com/embed/${videoId}`;
  }

  function convertYouTubePreview(url) {
    const videoId = url.split('/').pop();
    return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  }

  const handleIframeLoad = () => {
    setIsLoading(false); // Отключаем загрузку, когда iframe загружен
  };

  return (
    <>
      <Helmet>
      <meta property="og:title" content="AE2 Extension" />
      <meta property="og:description" content={`AE2 Overview`} />
      <meta property="og:image" content={convertYouTubePreview(ru)} />
      <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="user-popup__overlay" onClick={onClose}></div>
      <div className="user-popup">
        <button
          id="success-close-button"
          type="button"
          className="infotooltip__close-button"
          onClick={onClose}
        />
        {/* {isLoading && <Preloader isOpen={isLoading} />} */}
        <iframe 
          id="ytplayer" 
          type="text/html" 
          width={iframeSize.width} 
          height={iframeSize.height} 
          src={iframeSrc}
          frameBorder="0" 
          allowFullScreen
          onLoad={handleIframeLoad} // Обработчик события загрузки iframe
        ></iframe>
        {<div className="guide__copy-button" onClick={copyToClipboard}>{copySuccess || 'Поделиться'}</div>}
      </div>
    </>
  );
};

export default ButtonPopup;
